/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:7b44ea04-40bb-4614-90e9-f62a7b1a378c",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_C5EfxPbWK",
    "aws_user_pools_web_client_id": "5l2grqdkdiac38qclhl981bft8",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "SriwijayaClientWeb",
            "endpoint": "https://nl0e93g7ih.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_content_delivery_bucket": "sriwijayaclientweb-20200316115837-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d3f8116907285f.cloudfront.net"
};


export default awsmobile;
