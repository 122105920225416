import React from "react";
import Navigation from "../common/Navigation";

class App extends React.Component {
	constructor(props : any, context : any) {
		super(props, context);
	}

	render() {
		// @ts-ignore
		if (this.props.authState == "signedIn") {
			return (
				<Navigation/>
			);
		} else {
			return null;
		}
	}
}

export default App;