import React from "react";
import {VerifyContact} from "aws-amplify-react";
import {Button, Card, Col, Input, Radio, Row} from "antd";
import { Typography } from 'antd';
const { Title } = Typography;

export class CustomVerifyContact extends VerifyContact {
	constructor(props : any) {
		super(props);
	}

	verifyViewContact(){
		const user = this.props.authData;
		if (!user) {
			console.debug('no user for verify');
			return null;
		}
		const { unverified } = user;
		if (!unverified) {
			console.debug('no unverified on user');
			return null;
		}
		const { email, phone_number } = unverified;
		return (
			<div>
				{email ? (
					<Radio
						key="email"
						name="contact"
						value="email"
						onChange={this.handleInputChange}
						style={{
							color : "#fff"
						}}
					>Email</Radio>
				) : null}
				{phone_number ? (
					<Radio
						key="phone_number"
						name="contact"
						value="phone_number"
						onChange={this.handleInputChange}
					>Phone Number</Radio>
				) : null}
			</div>
		);
	}

	submitViewContact() {
		return (
			<div>
				<Input
					placeholder="Code"
					key="code"
					name="code"
					autoComplete="off"
					onChange={this.handleInputChange}
					style={{
						opacity: 0.7,
						height : "50px",
						backgroundColor : "#002950",
						color : "#fff",
						borderWidth : 0
					}}
				/>
			</div>
		);
	}

	showComponent(theme : any) {
		const { authData, hide } = this.props;
		return (
			<Row justify="center" align="middle" style={{
				minHeight: '100vh',
				display: "flex",
				justifyContent: "center",
				paddingTop : "100px",
				backgroundSize :"cover",
				backgroundColor: "#001529"
			}}>
				<div style={{
					paddingTop : "20px",
					paddingBottom : "20px",
					width : "300px",
					marginLeft : "auto",
					marginRight : "auto"}}>
					<Row justify="center" align="middle" style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
}						<img
							style={{
								height: "150px",
								float: "left",
								marginTop: 20,
								marginLeft: 20,
								marginRight: 8,
							}}
							src={"/logo.jpg"}
							alt="start"
						/>
					</Row>
					<Row>
						<Col span={24}>
							<Title level={4} style={{
								color : "#fff"
							}}>Account need verification</Title>
							{this.state.verifyAttr ? this.submitViewContact() : this.verifyViewContact()}
						</Col>
					</Row>
					<Row style={{
						textAlign: "center"
					}}>
						<br />
						<Button
							className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="default"
							// @ts-ignore
							onClick={() => this.changeState('signedIn', authData)}
							style={{
								width : "300px",
								height :"40px",
								opacity : 0.9,
								backgroundColor : "transparent",
								color : "white"
							}}
						>
							Skip
						</Button>
					</Row>
					<Row style={{
						textAlign: "center"
					}}>
						<br />
						<Button
							className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="primary"
							// @ts-ignore
							onClick={() => super.verify()}
							style={{
								width : "300px",
								height :"40px",
								opacity : 0.9
							}}
						>
							Verify
						</Button>
					</Row>
				</div>
			</Row>
		);
	}
}