import React from "react";
import {RequireNewPassword} from "aws-amplify-react";
import {Button, Card, Col, Input, Radio, Row} from "antd";
import { Typography } from 'antd';
import {I18n} from "@aws-amplify/core";
import {auth} from "aws-amplify-react/src/Amplify-UI/data-test-attributes";
const { Title } = Typography;

export class CustomRequireNewPassword extends RequireNewPassword {
	constructor(props : any) {
		super(props);
	}


	showComponent(theme : any) {

		const user = this.props.authData;
		const { requiredAttributes } = user.challengeParam;

		const { authData } = this.props;
		return (
			<Row justify="center" align="middle" style={{
				minHeight: '100vh',
				display: "flex",
				justifyContent: "center",
				paddingTop : "100px",
				backgroundSize :"cover",
				backgroundColor: "#001529"
			}}>
				<div style={{
					paddingTop : "20px",
					paddingBottom : "20px",
					width : "300px",
					marginLeft : "auto",
					marginRight : "auto"}}>
					<Row justify="center" align="middle" style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
}						<img
							style={{
								height: "150px",
								float: "left",
								marginTop: 20,
								marginLeft: 20,
								marginRight: 8,
							}}
							src={"/logo.jpg"}
							alt="start"
						/>
					</Row>
					<Row>
						<Col span={24}>
							<Title level={4} style={{
								color : "#fff"
							}}>Change your password</Title>
							<Input
								autoFocus
								placeholder={I18n.get('New Password')}
								key="password"
								name="password"
								type="password"
								onChange={this.handleInputChange}
								data-test={auth.requireNewPassword.newPasswordInput}
								style={{
									opacity: 0.7,
									height : "50px",
									backgroundColor : "#002950",
									color : "#fff",
									borderWidth : 0
								}}
							/>
							{requiredAttributes.map((attribute : any) => (
								<Row>
									<br />
									<Input
										placeholder={attribute}
										key={attribute}
										name={attribute}
										type="text"
										onChange={this.handleInputChange}
										style={{
											opacity: 0.7,
											height : "50px",
											backgroundColor : "#002950",
											color : "#fff",
											borderWidth : 0
										}}
									/>
								</Row>
							))}
						</Col>
					</Row>
					<Row style={{
						textAlign: "center"
					}}>
						<br />
						<Button
							className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="default"
							// @ts-ignore
							onClick={() => this.changeState('signIn')}
							style={{
								width : "300px",
								height :"40px",
								opacity : 0.9,
								backgroundColor : "transparent",
								color : "white"
							}}
						>
							Back to Sign In
						</Button>
					</Row>
					<Row style={{
						textAlign: "center"
					}}>
						<br />
						<Button
							className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="primary"
							// @ts-ignore
							onClick={() => super.change()}
							style={{
								width : "300px",
								height :"40px",
								opacity : 0.9
							}}
						>
							Change
						</Button>
					</Row>
				</div>
			</Row>
		);
	}
}