import useScript from '../hooks/useScript';
import React, {useEffect, useState} from "react";
import {Alert, Card, Col, Descriptions, Form, Input, Modal, Row, Statistic} from "antd";
import {API, Auth} from "aws-amplify";
import {act} from "react-dom/test-utils";
const { TextArea } = Input;
var moment = require('moment');
var momentTz = require('moment-timezone');

type CurrentBalanceProps = {
}

const CurrentBalanceComponent: React.FC<CurrentBalanceProps> = (props) => {
    const [queryLoading, setQueryLoading] = useState(false);
    const [currentBalance, setCurrentBalance] = useState("");
    const [successCount, setSuccessCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [totalTransactionsGMV, setTotalTransactionsGMV] = useState("");

    useEffect(() => {
        queryStatistics();
        handleQueryCurrentBalance();
    }, []);



    function queryStatistics() {
        queryGlobalStatisticsTransactions().then(response => {
            var payload = JSON.parse(response.data.responseBody.Payload);
            if (payload.success) {
                var data = payload.body;
                setSuccessCount(data.SUCCESS);
                setFailedCount(data.FAILED);
                setTotalTransactionsGMV(data.GMV);
            }
        }).catch(err => {
            console.log("Error : " + err);
        })
    }

    function toCurrency(numberString: any) {
        let number = parseFloat(numberString);
        return number.toLocaleString('ID');
    }

    function handleQueryCurrentBalance() {
        queryCurrentBalance().then(response => {
            var payload = JSON.parse(response.data.responseBody.Payload);
            if (payload.success) {
                var data = payload.body;
                setCurrentBalance(toCurrency(data.balance));
            }
        }).catch(err => {
            console.log("Error : " + err);
        })
    }

    async function queryGlobalStatisticsTransactions(){
        var id = await Auth.currentUserInfo();

        let myInit = {
            headers: {},
            response: true,
            queryStringParameters: {
                resellerId : id.username
            }
        };

        var date =  momentTz().tz('Asia/Jakarta').format("YYYYMMDD");

        return API.get('SriwijayaClientWeb', '/api/transactionsStatistics/' + date, myInit);
    }

    async function queryCurrentBalance(){
        var id = await Auth.currentUserInfo();

        let myInit = {
            headers: {},
            response: true,
            queryStringParameters: {
            }
        };


        return API.get('SriwijayaClientWeb', '/api/resellers/' + id.username, myInit);
    }

    // @ts-ignore
    return (
        <div>
            <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Success"
                            value={successCount}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix=""
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Failed"
                            value={failedCount}
                            precision={0}
                            valueStyle={{ color: '#cf1322' }}
                            suffix=""
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Transactions"
                            value={totalTransactionsGMV}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix=""
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Current Balance" value={currentBalance} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};



export default CurrentBalanceComponent;