import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Icon,
    Input,
    PageHeader,
    Row,
    Table, Tag,
    Typography
} from "antd";
import {API, Auth} from "aws-amplify";
import Highlighter from "react-highlight-words";
import CurrentBalanceComponent from "../../common/CurrentBalance";


type Props = {
}

const Products: React.FC<Props> = (props) => {
    const [loadingData, setLoadingData] = useState(false);
    const [inboxData, setInboxData] = useState([]);
    const [searchInput, setSearchInput] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchColumn] = useState('');

    useEffect(() => {
        doQueryData();
    }, []);


    function handleSearch(selectedKeys : any, confirm : any, dataIndex:any){
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    }

    function handleReset(clearFilters:any){
        clearFilters();
        setSearchText('');
    }


    const getColumnSearchProps = (dataIndex:any) => ({
        // @ts-ignore
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        // @ts-ignore
                        setSearchInput(node);
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered:any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value:any, record:any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible:any) => {
            if (visible) {
                // @ts-ignore
                setTimeout(() => searchInput.select());
            }
        },
        render: (text:any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });


    function doQueryData() {
        setLoadingData(true);
        getProducts().then(response => {
            parseResponse(response);
        }).catch(err => {
            setLoadingData(false);
        })
    }


    function parseItems(items : any) {
        var newItems = [];
        for (var i in items) {
            var item = items[i];
            newItems.push(item);
        }

        return newItems;
    }

    function parseResponse(response : any) {
        setLoadingData(false);
        var payload = JSON.parse(response.responseBody.Payload);
        var body = payload.body;
        let newItems = parseItems(body);

        // @ts-ignore
        setInboxData(newItems);
    }

    function toCurrency(numberString: any){
        let number = parseFloat(numberString);
        return number.toLocaleString('ID');
    }


    async function getProductParams() {
        var id = await Auth.currentUserInfo();

        return {
            headers: {},
            queryStringParameters: {
                resellerId: id.username
            }
        };
    }

    async function getProducts() {
        let params = await getProductParams();
        return API.get('SriwijayaClientWeb', '/api/resellerShowProducts/1' , params);
    }

    function getTagColor(record: any){
        if (record.isActive) {
            return 'green';
        } else {
            return 'red';
        }
    };

    function getTagMessage(record: any){
        if (record.isActive) {
            return 'ACTIVE';
        } else {
            return 'INACTIVE';
        }
    };

    const modalColumns = [
        {
            title: 'ProductID',
            dataIndex: 'productId',
            sorter: (a: { productId: string; }, b: { productId: string; }) => a.productId.localeCompare(b.productId),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('productId'),

        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            sorter: (a: { productName: string; }, b: { productName: string; }) => a.productName.localeCompare(b.productName),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('productName'),

        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            sorter: (a: { provider: string; }, b: { provider: string; }) => a.provider.localeCompare(b.provider),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('provider'),

        },
        {
            title: 'Quantity',
            dataIndex: 'stockQuantity',
            sorter: (a: { stockQuantity: string; }, b: { stockQuantity: string; }) => a.stockQuantity.localeCompare(b.stockQuantity),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('stockQuantity'),

        },
        {
            title: 'isActive',
            key: 'isActive',
            dataIndex: 'isActive',
            render: (isActive: any, record: any) => (
                <span>
						<Tag color={getTagColor(record)}>
							{getTagMessage(record)}
						</Tag>
				  	</span>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: (a: { balance: number; }, b: { balance: number; }) => a.balance - b.balance,
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',
            ...getColumnSearchProps('price'),
            render: (text : any, record : any) => (
                <Typography.Text>
                    {toCurrency(text)}
                </Typography.Text>
            )
        }
    ];

    return (

        <div>
            <Row>
                <CurrentBalanceComponent/>
            </Row>
            <Row>
                <Col span={24} >
                    <Card bordered={false}>
                        <PageHeader
                            style={{
                                border: '1px solid rgb(235, 237, 240)',
                            }}
                            title="Products"
                            subTitle="Available products"
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card bordered={false}>
                        <Table
                            size="small"
                            loading={loadingData}
                            bordered
                            pagination={{
                                position: "bottom",
                                pageSize: 25}}
                            scroll={{ x: true }}
                            dataSource={inboxData}
                            // @ts-ignore
                            columns={modalColumns}
                        />;
                    </Card>

                </Col>
            </Row>
        </div>
    );
};



export default Products;