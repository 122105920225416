import React from "react";
import { SignIn } from "aws-amplify-react";
import {Button, Card, Col, Input, Row} from "antd";

export class CustomSignIn extends SignIn {
	constructor(props : any) {
		super(props);
		this._validAuthStates = ["signIn", "signedOut"];
	}

	showComponent(theme : any) {
		// @ts-ignore
		return (
			<Row justify="center" align="middle" style={{
				minHeight: '100vh',
				display: "flex",
				paddingTop : "100px",
				justifyContent: "center",
				backgroundSize :"cover",
				backgroundColor: "#001529"
			}}>
				<div style={{
					paddingTop : "20px",
					paddingBottom : "20px",
					width : "300px",
					marginLeft : "auto",
					marginRight : "auto"}}>
					<Row justify="center" align="middle" style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
}						<img
							style={{
								height: "150px",
								float: "left",
								marginTop: 20,
								marginLeft: 20,
								marginRight: 8,
							}}
							src={"/logo.jpg"}
							alt="start"
						/>
					</Row>
					<Row>
						<Col span={24}>
							<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
								<div className="mb-4">
									<Input
										className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
										id="username"
										key="username"
										name="username"
										onChange={this.handleInputChange}
										type="text"
										placeholder="Username"
										style={{
											opacity: 0.7,
											height : "50px",
											backgroundColor : "#002950",
											color : "#fff",
											borderWidth : 0
										}}
									/>
								</div>
								<br />
								<div className="mb-6">
									<Input
										className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
										id="password"
										key="password"
										name="password"
										onChange={this.handleInputChange}
										type="password"
										placeholder="Password"
										style={{
											opacity: 0.7,
											height : "50px",
											backgroundColor : "#002950",
											color : "#fff",
											borderWidth : 0
										}}
									/>
								</div>
								<Row style={{
									textAlign: "center"
								}}>
									<br />
									<Button
										className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
										type="primary"
										// @ts-ignore
										onClick={() => super.signIn()}
										style={{
											width : "300px",
											height :"40px",
											opacity : 0.9
										}}
									>
										Login
									</Button>
								</Row>
							</form>
						</Col>
					</Row>

				</div>

			</Row>
		);
	}
}