import {Menu, Row, Col, Layout, Icon} from 'antd';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import React, {Suspense, useState} from "react";
import Products from "../components/products/Products";
import {Auth} from "aws-amplify";

const DownloadReport = React.lazy(() => import("../components/transactionHistory/DownloadReport"));
const TransactionHistory = React.lazy(() => import("../components/transactionHistory/TransactionHistory"));
const BalanceMutation = React.lazy(() => import("../components/reseller/BalanceMutation"));
const TransactionSummary = React.lazy(() => import("../components/transactionHistory/TransactionSummary"));
const DepositReport = React.lazy(() => import("../components/reseller/DepositReport"));
const BulkOrder = React.lazy(() => import("../components/bulkOrder/BulkOrder"));
const CreateDeposit = React.lazy(() => import("../components/deposit/CreateDeposit"));
const Deposit = React.lazy(() => import("../components/deposit/Deposit"));
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

type Props = {}
const Navigation: React.FC<Props> = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [hideLogoClassName, setHideLogoClassName] = useState('show');

    function onCollapse(collapsed: boolean) {
        setCollapsed(collapsed);

        if (collapsed) {
            setHideLogoClassName('hide');
        } else {
            setHideLogoClassName('show');
        }
    }

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <Router>
            <div>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider collapsible collapsed={collapsed} onCollapse={(collapsed) => onCollapse(collapsed)}>
                        <Menu
                            defaultOpenKeys={['monitoring']}
                            mode="inline"
                            theme="dark">

                            <SubMenu
                                key="monitoring"
                                title={
                                    <span>
                            <Icon type="pic-left" />
                            <span>Menu</span>
                            </span>
                                            }
                                        >
                                <Menu.Item key="transactionHistory">
                                    <Link to="/transactionHistory">Transactions History</Link>
                                </Menu.Item>
                                <Menu.Item key="transactionSummary">
                                    <Link to="/transactionSummary">Transactions Summary</Link>
                                </Menu.Item>
                                <Menu.Item key="createDeposit">
                                    <Link to="/createDeposit">Create Ticket Deposit</Link>
                                </Menu.Item>
                                <Menu.Item key="deposit">
                                    <Link to="/deposit">List Deposit Request</Link>
                                </Menu.Item>
                                <Menu.Item key="balanceMutation">
                                    <Link to="/balanceMutation">Balance Mutation</Link>
                                </Menu.Item>
                                <Menu.Item key="depositReport">
                                    <Link to="/depositReport">Deposit Report</Link>
                                </Menu.Item>
                                <Menu.Item key="products">
                                    <Link to="/products">Products</Link>
                                </Menu.Item>
                                <Menu.Item key="downloadReport">
                                    <Link to="/downloadReport">Download Report</Link>
                                </Menu.Item>
                                <Menu.Item key="bulkOrder">
                                    <Link to="/bulkOrder">Bulk Order</Link>
                                </Menu.Item>
                                <Menu.Item key="signout">
                                    <a onClick={() => signOut()}>
                                        Logout
                                    </a>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content style={{ margin: '0 16px' }}>
                            <Switch>
                                <Route path="/transactionHistory">
                                    <Suspense fallback={<div>Loading...</div>}>
                                    <TransactionHistory />
                                    </Suspense>
                                </Route>
                                <Route path="/transactionSummary">
                                    <Suspense fallback={<div>Loading...</div>}>
                                    <TransactionSummary />
                                    </Suspense>
                                </Route>
                                <Route path="/createDeposit">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <CreateDeposit />
                                    </Suspense>
                                </Route>
                                <Route path="/deposit">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Deposit />
                                    </Suspense>
                                </Route>
                                <Route path="/balanceMutation">
                                    <Suspense fallback={<div>Loading...</div>}>
                                    <BalanceMutation/>
                                    </Suspense>
                                </Route>
                                <Route path="/depositReport">
                                    <Suspense fallback={<div>Loading...</div>}>
                                    <DepositReport/>
                                    </Suspense>
                                </Route>
                                <Route path="/downloadReport">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <DownloadReport/>
                                    </Suspense>
                                </Route>
                                <Route path="/products">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Products/>
                                    </Suspense>
                                </Route>
                                <Route path="/bulkOrder">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <BulkOrder/>
                                    </Suspense>
                                </Route>
                                <Route path="/">
                                    <Suspense fallback={<div>Loading...</div>}>
                                    <TransactionHistory />
                                    </Suspense>
                                </Route>
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        </Router>
    );
};

export default Navigation;
