import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {
    RequireNewPassword,
    SignIn,
    Greetings,
    VerifyContact, Authenticator
} from 'aws-amplify-react';

import 'antd/dist/antd.css';
import Navigation from "./common/Navigation";
import { AmplifyTheme } from "aws-amplify-react";
import {CustomSignIn} from "./auth/CustomSignIn";
import AppWithAuth from "./auth/AppWithAuth";
import {CustomVerifyContact} from "./auth/CustomVerifyContact";
import {CustomRequireNewPassword} from "./auth/CustomRequireNewPassword";

Amplify.configure(awsconfig);

class App extends React.Component {
    constructor(props : any, context : any) {
        super(props, context);
    }

    render() {
        return (
            <div>
                <Authenticator hide={[SignIn, Greetings, VerifyContact, RequireNewPassword]} amplifyConfig={awsconfig}>
                    <CustomSignIn />
                    <CustomRequireNewPassword />
                    <CustomVerifyContact />
                    <AppWithAuth />
                </Authenticator>
            </div>
        );
    }
}

export default App;